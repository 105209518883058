<template>
  <div id="topic">
    <template v-show="!userLoginForm.showUserLoginBox">
      <div class="homepage-left">
        <div class="heads">
          <p>题库录入</p>
        </div>
        <el-form ref="form" label-width="60px" class="inputForm">
          <el-form-item label="年级:" prop="grade">
            <el-select v-model="select.grade" placeholder="请选择年级" @change="gradeChangeEvent"
                       :key="'selectGrade'" :id="select.gradeId">
              <el-option v-for="item in gradeList" :key="item.id" :label="item.value"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科:">
            <el-select v-model="select.subject" placeholder="请选择学科" :key="'selectSubject'"
                       @change="subjectChangeEvent" :id="select.subjectId">
              <el-option v-for="item in subjectList" :key="item.id" :label="item.value"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教辅:" style="width:100%">
            <el-select v-model="select.teachingAids" placeholder="请选择教辅"
                       :key="'selectTeachingAids'" :id="select.teachingAidsId" @change="teachChange">
              <el-option v-for="item in teachingAidsList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="章节:">
            <el-cascader :props="{ value: 'id', label: 'name', children: 'children' }" v-model="chaptValue" :options="chapterList" placeholder="请选择章节" @change="chaptChange"></el-cascader>
          </el-form-item>
          <el-form-item label="课时:">
            <el-cascader :props="{ value: 'id', label: 'name', children: 'children' }" v-model="select.classHourIds" :options="lessonList" placeholder="请选择课时"></el-cascader>
            <!-- <el-select v-model="select.classHourIds" placeholder="请选择课时">
              <el-option v-for="item in lessonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="题型:">
            <el-select v-model="select.questionType" placeholder="请选择题型"
                       :id="select.questionTypeId" :key="'selectQuestionType'">
              <el-option v-for="item in questionTypeList" :key="item.questionTypeValue" :label="item.questionTypeName"
                         :value="item.questionTypeValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题号:">
            <el-input v-model="select.questionNumber" :id="select.questionNumberId"
                      placeholder="例如14.1"></el-input>
          </el-form-item>
        </el-form>

        <div style="padding: 0 20px;">
          <el-tabs v-model="editAreaActiveName">
            <el-tab-pane label="题干" :name="edit_tags.tg">
              <div class="homepage-left-middle">
                <el-button class="homepage-left-middle-btn" @click="editorFraction">分数</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorImage">图片</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorUnderline">下划线</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorNewLine">换行</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorKeySymbol">着重号</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editAddSpace">空格</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddDivide">除号</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddGreater ">大于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddLess">小于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddAnd">和</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editTimes">乘</el-button>
                <el-button class="homepage-left-middle-btn" @click="underWavy">波浪线</el-button>
                <el-button class="homepage-left-middle-btn" @click="textUnderline">文字下划线</el-button>
              </div>
              <div>
                <el-input
                  type="textarea"
                  class="editor_textarea"
                  :rows="11"
                  :id="edit_tags.tg"
                  ref="tg"
                  v-model="edit_area_content.tgContent"
                  @keyup.enter.native="editorNewLine"
                  @keyup.space.native="editKeyupSpace"
                  placeholder="请输入题干内容"></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane label="答案" :name="edit_tags.da">
              <div class="homepage-left-middle">
                <el-button class="homepage-left-middle-btn" @click="editorFraction">分数</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorImage">图片</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorUnderline">下划线
                </el-button>
                <el-button class="homepage-left-middle-btn" @click="editorNewLine">换行</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorKeySymbol">着重号</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editAddSpace">空格</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddDivide">除号</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddGreater ">大于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddLess">小于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddAnd">和</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editTimes">乘</el-button>
                <el-button class="homepage-left-middle-btn" @click="underWavy">波浪线</el-button>
                <el-button class="homepage-left-middle-btn" @click="textUnderline">文字下划线</el-button>
              </div>
              <div>
                <el-input type="textarea" class="editor_textarea" :rows="11" :id="edit_tags.da" ref="da"
                          v-model="edit_area_content.daContent"
                          @keyup.enter.native="editorNewLine"
                          @keyup.space.native="editKeyupSpace"
                          placeholder="请输入答案内容"></el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane label="解析" :name="edit_tags.jx">
              <div class="homepage-left-middle">
                <el-button class="homepage-left-middle-btn" @click="editorFraction">分数</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorImage">图片</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorUnderline">下划线
                </el-button>
                <el-button class="homepage-left-middle-btn" @click="editorNewLine">换行</el-button>
                <el-button class="homepage-left-middle-btn" @click="editorKeySymbol">着重号</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editAddSpace">空格</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddDivide">除号</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddGreater ">大于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddLess">小于等于</el-button>
                <el-button class="homepage-left-middle-btn" @click="editAddAnd">和</el-button>
              </div>
              <div class="homepage-left-middle" style="margin-top:15px">
                <el-button class="homepage-left-middle-btn" @click="editTimes">乘</el-button>
                <el-button class="homepage-left-middle-btn" @click="underWavy">波浪线</el-button>
                <el-button class="homepage-left-middle-btn" @click="textUnderline">文字下划线</el-button>
              </div>
              <div>
                <el-input type="textarea" class="editor_textarea" :rows="11" :id="edit_tags.jx" ref="jx"
                          v-model="edit_area_content.jxContent"
                          @keyup.enter.native="editorNewLine"
                          @keyup.space.native="editKeyupSpace"
                          placeholder="请输入解析内容"></el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="homepage-middle topic_flex">
        <div class="heads" style="display: flex;">
          <p>操作区</p>
        </div>
        <div class="Operation topic_flex_item">
          <el-tabs v-model="handlerAreaActiveName" @tab-click="handleClick">
            <el-tab-pane v-for="page in pageNumberList" v-bind:key="page"
                         :label="'第'+page+'页'"
                         :name="'n'+page">
              <div :id="'display_area_'+page" style="width:100%;max-height:550px;"
                   class="display_area">
                <div :id="'canvas_layer_'+page" class="canvas_layer">
                  <canvas :id="'image_canvas_'+page" class="image_canvas"></canvas>
                  <canvas :id="'region_canvas_'+page" class="region_canvas"></canvas>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="homepage-middle-middle">
            <span>坐标:<span v-if="alreadySelectCoordinateCount>0"> 已选择<span
              style="color:#409EFF; size:18px; font-weight: bold">{{
                alreadySelectCoordinateCount
              }}</span>个</span></span>
          <el-input type="textarea" style="margin:10px auto" v-html="coordinateShowText"></el-input>
        </div>
        <div class="homepage-middle-btn">
          <el-button class="homepage-middle-btn1" @click="selectUploadPicture">选择图片</el-button>
          <el-button class="homepage-middle-btn2" @click="clearOperation">清空操作区</el-button>
          <el-button class="homepage-middle-btn2" @click="clearPosition">清空坐标</el-button>
        </div>
      </div>
      <el-dialog title="输入页码" :visible.sync="showSelectPicture" width="25%" center>
      <span style="text-align: center;">
      <p v-html="showTeachingAids"></p>
      <div class="homepage-page">
        <p @click="subtract()" class="page_handle">-</p>
        <p style="margin-top: 10px;">第</p>
          <p><input class="nums" type="number" name="page" id="page" v-model="pageNumber"/> </p>
        <p style="margin-top: 10px;">页</p>
        <p @click="add()" class="page_handle">+</p>
      </div>
      </span>
        <span slot="footer" class="dialog-footer"><el-button id="page-action-ok" @click="pageActionOk">确 定</el-button></span>
      </el-dialog>
      <div class="homepage-right topic_flex">
        <div class="headss">
          <p>题干预览</p>
        </div>
        <div class="homepage-right-header topic_flex_item" style="overflow: auto;">
          <div style="width:100%;height:100%">
            <div class="preview-area">
              <p v-html="edit_area_content.tgContent" style="line-height: 25px;letter-spacing: 1px;"></p>
            </div>
          </div>
        </div>
        <div class="headss">
          <p>答案预览</p>
        </div>
        <div class="homepage-right-middle topic_flex_item" style="overflow: auto;">
          <div>
            <div class="preview-area">
              <p v-html="edit_area_content.daContent" style="line-height: 25px;letter-spacing: 1px;"></p>
            </div>
          </div>
        </div>
        <div class="headss">
          <p>解析预览</p>
        </div>
        <div class="homepage-right-footer topic_flex_item" style="overflow: auto;">
          <div>
            <div class="preview-area">
              <p v-html="edit_area_content.jxContent" style="line-height: 25px;letter-spacing: 1px;"></p>
            </div>
          </div>
        </div>
        <div class="home-right-btn">
          <!-- <el-button class="check" @click="checkPopup" :loading="checkQuestion">题目查重</el-button> -->
          <el-button class="complete" @click="addItemBankEvent" :loading="addItemBankLoading">完成</el-button>
        </div>
      </div>
    </template>
    <!-- 教材列表 -->
    <template>
      <el-dialog
        title="选择页码"
        :visible.sync="materialDialogVisible"
        width="480px"
        :before-close="()=>{materialDialogVisible=false}">
        <div class="materialBox">
          <div v-for="(item,index) in materialCount" :key="index" @click="getImage(item)">{{ item }}</div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="materialDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="materialBtnOk">确 定</el-button>
        </span> -->
      </el-dialog>
    </template>
    <!--area image upload-->
    <template>
      <el-upload class="avatar-uploader" action="" :show-file-list="false"
                 :before-upload="beforeAreaImageAvatarUpload"
                 accept="image/png,image/jpeg" id="handler-area-image-upload">
      </el-upload>
    </template>
    <!--input image upload-->
    <template>
      <el-upload class="avatar-uploader" action="" :show-file-list="false"
                 :before-upload="beforeInputImageAvatarUpload"
                 :on-change="inputImageChangeEvent"
                 accept="image/png,image/jpeg" id="input-area-image-upload">
      </el-upload>
    </template>
    <!--分数-->
    <template>
      <el-dialog
        title="提示"
        :visible.sync="fractionDialogVisible"
        width="480px"
        :before-close="()=>{fractionDialogVisible=false}">
        <div>
          分子:
          <el-input
            style="width:136px;"
            v-model="fraction.molecule"
            placeholder="请输入分子">
          </el-input>
          &nbsp;&nbsp;&nbsp;&nbsp;分母:
          <el-input
            style="margin-left: 10px;width:136px;"
            v-model="fraction.denominator"
            placeholder="请输入分母">
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fractionDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="fractionActionOk">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 查重弹框 -->
    <template>
      <el-dialog
        title=""
        :show-close="false"
        :visible.sync="centerDialogVisible"
        width="70%"
        center>
        <div class="checkContent" id="checkText">
          <div class="checkLeft">
            <div class="checkTitle"><p>原题</p></div>
            <div class="checkText">
              <p v-html="edit_area_content.tgContent" style="line-height: 25px;letter-spacing: 1px;"></p>
            </div>
          </div>
          <div class="checkRight">
            <div class="checkTitle"><p>相似题</p></div>
            <div class="checkText" style="line-height: 25px;letter-spacing: 1px;">
              <div class="context" v-for="(item, index) in checkQuestionList" :key="index">
                <div class="headers">
                  <!-- <el-checkbox style="transform: scale(1.3)" v-model="item.repeatId" @change="handleCheckedChange"></el-checkbox> -->
                  <el-radio-group v-model="checked">
                    <el-radio :label="item.bankId" @click.native.prevent="clickitem(item)">
                      <!-- <span class="similar">相似度：{{item.score.toFixed(2)}}%</span> -->
                      <span class="similar">第{{ index + 1 }}题</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="contents" v-html="item.stem"></div>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button style="width: 120px" @click="cancelBtn">取 消</el-button>
          <el-button style="background-color: #1EB180;border: #1EB180 1px solid;width: 120px" type="primary"
                     @click="submitBtn">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>
<script>

import { definedKeySymbolStyle, insertText, KEY_SYMBOL_CLASS_NAME } from '@/utils/common.fun'
import DrawTool from '@/drawTool'
import { getMaterialImage, getMaterialList } from '@/api/material'
import { getGradeName, getSubjectName, grade, subject } from '@/utils/enums'
import { SETTING } from '@/config/setting'
import { addQuestionBank, chekRepeat, imageUpload, questionTypeList, getChapterList, teachingMaterialLessonList } from '@/api/question-bank'

const YANJU_CHARS = {
  NUMERATOR: '*yanju_numerator*', // 分子
  DENOMINATOR: '*yanju_denominator*' // 分母
}
// 分数字符串
const STR_FRACTION = '\\(\\frac{' + YANJU_CHARS.NUMERATOR + '}{' + YANJU_CHARS.DENOMINATOR + '}\\)'
// 下划线
const STR_UNDERLINE = '\\(\\underline{~~~~~}\\)'
// 题干
const TG_NAME = 'tg'
// 答案预览
const DA_NAME = 'da'
// 解析
const JX_NAME = 'jx'
export default {
  mounted () {
    this.subjectChangeEvent()
    this.getQuestionTypeList(this.select.grade, this.select.subject)
  },
  computed: {
    showTeachingAids () {
      let teachingAids = null
      this.teachingAidsList.forEach(item => {
        if (item.id === this.select.teachingAids) {
          teachingAids = item.name
        }
      })
      return `<span>${getGradeName(this.select.grade)} </span><span>${getSubjectName(this.select.subject)} ${teachingAids}</span>`
    },
    materialCount () {
      let count = 0
      this.teachingAidsList.forEach(item => {
        if (item.id === this.select.teachingAids) {
          count = item.pageCount
        }
      })
      return count
    }
  },
  watch: {
    edit_area_content: {
      handler (newVal, oldVal) {
        const that = this
        that.$nextTick(function () {
          that.commonsVariable.MathQueue()
        })
      },
      deep: true
    },
    // 切换教辅清空之前已选择的图片
    'select.teachingAids': {
      handler (newVal, oldVal) {
        this.pageNumberList = []
      }
    }
  },
  data () {
    return {
      // 特殊字符对话框显示
      fractionDialogVisible: false,
      showSpecialSymbols: false,
      addItemBankLoading: false,
      checkQuestion: false,
      centerDialogVisible: false,
      userLoginForm: {
        // user login box
        showUserLoginBox: false,
        userPhone: null,
        smsCode: null,
        errorMsg: null
      },
      // loading实例
      loadingObject: null,
      settings: {
        // 最大操作区域tab个数
        maxHandlerAreaTabCount: SETTING.NUMBER.TWO,
        // 最大上传图片大小
        maxImageSize: SETTING.NUMBER.N1024 * SETTING.NUMBER.N1024 * 3
      },
      labelPosition: 'right',
      // 默认页数
      pageNumber: SETTING.NUMBER.ONE,
      // 下拉选择值
      select: {
        // 年级
        grade: 'FIFTH_GRADE',
        gradeId: 'selectGrade',
        // 学科
        subject: 'MATHEMATICS',
        subjectId: 'selectSubject',
        // 题型
        questionType: null,
        questionTypeId: 'questionType',
        // 题号
        questionNumber: null,
        questionNumberId: 'questionNumber',
        // 教辅
        teachingAids: null,
        teachingAidsId: 'teachingAids',
        // 课时
        classHourIds: []
      },
      chaptValue: [],
      chapterList: [],
      chapterIds: [],
      chapterNames: [],
      chaptNameIdList: [],
      lessonList: [],
      edit_tags: {
        tg: TG_NAME,
        da: DA_NAME,
        jx: JX_NAME
      },
      // 年级列表
      gradeList: grade,
      // 教辅列表
      teachingAidsList: [],
      // 学科列表
      subjectList: subject,
      // 题库列表
      questionTypeList: [],
      // 编辑区内容保存
      edit_area_content: {
        // 题干内容
        tgContent: null,
        // 答案内容
        daContent: null,
        // 解析内容
        jxContent: null
      },
      // 分数
      fraction: {
        // 分子
        molecule: null,
        // 分母
        denominator: null
      },
      // 编辑区
      editAreaActiveName: TG_NAME,
      // 操作区
      handlerAreaActiveName: null,
      showSelectPicture: false,
      imgSrc: '',
      // 页面number列表
      pageNumberList: [],
      // 保存DT对象
      dbObjectList: [],
      // 保存坐标
      coordinateList: [],
      // 坐标显示文本
      coordinateShowText: null,
      // 已选择坐标个数
      alreadySelectCoordinateCount: SETTING.NUMBER.ZERO,
      // 查重题目列表
      checkQuestionList: [],
      checked: '',
      propertyId: '',
      materialDialogVisible: false,
      imageUrl: '',
      coordList: [],
      pageCountList: [],
      chapters: []
    }
  },
  methods: {
    // 获取章节列表
    getChapterList (standardMaterialId) {
      getChapterList(standardMaterialId).then(res => {
        if (res.code === 0) {
          const chapterList = res.data.list
          this.chapters = res.data.chapters
          if (chapterList && chapterList.length) {
            this.chapterList = this.formatChapt(chapterList)
          }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 格式化章节数据
    formatChapt (list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.formatChapt(item.children)
        } else if (item.children && item.children.length === 0) {
          delete item.children
        }
      })
      return list
    },
    // 获取课时列表
    teachingMaterialLessonList (materialId, chapterId) {
      teachingMaterialLessonList({ materialId, chapterId }).then(res => {
        if (res.code === 0) {
          const lessonList = res.data.list
          if (lessonList && lessonList.length > 0) {
            this.lessonList = this.formatChapt(lessonList)
          }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 选择教辅
    teachChange (e) {
      this.chapterList = []
      this.chapters = []
      this.lessonList = []
      this.chaptValue = []
      this.select.classHourIds = []
      const list = this.teachingAidsList
      if (list && list.length) {
        list.forEach(item => {
          if (item.id === e) {
            this.getChapterList(item.standardMaterialId)
          }
        })
      }
    },
    // 选择章节
    chaptChange () {
      this.select.classHourIds = []
      this.lessonList = []
      const materialId = Number(this.chaptValue.slice(-1)[0])
      if (this.chapters && this.chapters.length) {
        this.chaptNameIdList = this.getChaptNameId(materialId, this.chapters)
      }
      this.teachingMaterialLessonList(this.select.teachingAids, materialId)
    },
    // 获取所选章节名称和id
    getChaptNameId (id, list) {
      const arr = []
      list.map(item => {
        if (item.id === id) {
          arr.push(item)
        }
      })
      return arr
    },
    clickitem (item) {
      if (item.bankId === this.checked) {
        this.checked = '' // 如果点击的对象是area就将v-model的值清空 radio状态为空
        this.propertyId = ''
      } else {
        this.checked = item.bankId // 否则就把点击的值赋值给area 即绑定的radio
        this.propertyId = item.id
      }
    },
    // 清空操作区
    clearOperation () {
      this.clearPosition()
      this.pageNumberList = []
    },
    // 清空坐标
    clearPosition () {
      if (this.coordinateList.length > 0) {
        this.dbObjectList.forEach(item => {
          item.clearCanvasV2()
        })
        this.coordinateList = []
        this.coordinateShowText = null
        this.alreadySelectCoordinateCount = SETTING.NUMBER.ZERO
      }
    },
    // 中间区域图片上传
    selectUploadPicture () {
      if (this.select.grade == null) {
        this.$message({ type: 'error', message: '年级未选择' })
        this.itemFocus(this.select.gradeId)
        return
      } else if (this.select.subject == null) {
        this.$message({ type: 'error', message: '学科未选择' })
        this.itemFocus(this.select.subjectId)
        return
      } else if (this.select.teachingAids == null) {
        this.$message({ type: 'error', message: '教辅未选择' })
        this.itemFocus(this.select.teachingAidsId)
        return
      }
      // this.showSelectPicture = true
      this.materialDialogVisible = true
    },
    // 获取题型列表
    getQuestionTypeList (grade, subject) {
      this.questionTypeList = []
      questionTypeList(grade, subject).then(res => {
        if (res.code === 0) {
          this.questionTypeList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 根据教辅ID和页码获取图片url
    getMaterialImage (materialId, pageNumber) {
      getMaterialImage(materialId, pageNumber).then(res => {
        if (res.code === 0) {
          this.imageUrl = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).then(() => {
        const self = this
        const displayAreaObject = document.getElementById('display_area_' + pageNumber)
        const regionCanvasObject = document.getElementById('region_canvas_' + pageNumber)
        const imageCanvasObject = document.getElementById('image_canvas_' + pageNumber)
        const canvasLayerObject = document.getElementById('canvas_layer_' + pageNumber)

        function syncPosition () {
          self.alreadySelectCoordinateCount = SETTING.NUMBER.ZERO
          const data = self.dbObjectList[pageNumber].pick() || []
          if (data.length > SETTING.NUMBER.ZERO) {
            self.coordinateList[pageNumber] = data
          }
          // 输出坐标显示
          let coordinateText = ''
          self.coordinateList.forEach((list, page) => {
            coordinateText += '页数:' + page + '<br />'
            list.forEach((v, ix) => {
              self.alreadySelectCoordinateCount++
              const pos = self.handlerPosition(v)
              coordinateText += (ix + SETTING.NUMBER.ONE) + ': ' + JSON.stringify(pos) + '<br />'
            })
          })
          self.coordinateShowText = coordinateText
        }

        const dt = new DrawTool(displayAreaObject, regionCanvasObject, imageCanvasObject, canvasLayerObject, syncPosition)
        // 缓存到列表中
        self.dbObjectList[pageNumber] = dt
        dt.clearCanvas()
        dt.loadImage(this.imageUrl).then(() => {
          // 同步加载完毕后执行
          console.log('do nothing')
        }).catch(() => {
          this.$message({ type: 'error', message: '同步加载失败,请刷新页面重新操作' })
        })
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 点击页码获取图片
    getImage (item) {
      this.pageNumberList.push(item)
      this.handlerAreaActiveName = 'n' + item
      this.coordinateList[item] = []
      this.getMaterialImage(this.select.teachingAids, item)
      this.materialDialogVisible = false
    },
    // 年级change事件
    gradeChangeEvent (e) {
      if (this.select.grade != null && this.select.subject != null) {
        this.getMaterialApi()
        this.getQuestionTypeList(this.select.grade, this.select.subject)
      }
    },
    // 学科change事件
    subjectChangeEvent () {
      if (this.select.grade != null && this.select.subject != null) {
        this.getMaterialApi()
        this.getQuestionTypeList(this.select.grade, this.select.subject)
      }
    },
    // 获取教辅列表接口
    getMaterialApi () {
      getMaterialList(this.select.grade, this.select.subject).then(res => {
        if (this.checkApiData(res)) {
          const self = this
          const data = res.data
          self.teachingAidsList = []
          self.select.teachingAids = null
          data.forEach((item) => {
            self.teachingAidsList.push({
              id: item.id,
              name: item.name + '(' + item.printingTimes + ')',
              pageCount: item.pageCount,
              standardMaterialId: item.standardMaterialId
            })
          })
        }
      })
    },
    // 校验网络请求
    checkApiData (res) {
      if (res.code !== SETTING.NUMBER.ZERO) {
        this.$message({ type: 'error', message: res.msg })
        return false
      }
      return true
    },
    // 获取焦点
    itemFocus (id) {
      document.getElementById(id).focus()
    },
    // 题目查重
    // checkPopup () {
    //   // if (!this.edit_area_content.tgContent) {
    //   //   return this.$message.warning('请输入题干内容')
    //   // }
    //   const that = this
    //   that.$nextTick(function () {
    //     if (that.commonsVariable.isMathjaxConfig) {
    //       that.commonsVariable.initMathjaxConfig()
    //     }
    //     that.commonsVariable.MathQueue('topicBox-right-header')
    //   })
    //   chekRepeat({
    //     stem: this.edit_area_content.tgContent,
    //     subject: this.select.subject,
    //     grade: this.select.grade
    //   }).then(res => {
    //     if (res.code === 0) {
    //       this.checkQuestionList = res.data
    //     } else {
    //       this.$message.error(res.msg)
    //     }
    //   }).then(() => {
    //     that.$nextTick(function () {
    //       if (that.commonsVariable.isMathjaxConfig) {
    //         that.commonsVariable.initMathjaxConfig()
    //       }
    //       that.commonsVariable.MathQueue('checkText')
    //     })
    //   }).catch(err => {
    //     this.centerDialogVisible = true
    //     this.$message.error(err)
    //   })
    // },
    // 查重弹框取消按钮
    cancelBtn () {
      this.centerDialogVisible = false
    },
    // 查重弹框确认按钮
    submitBtn () {
      // if (!this.checked) {
      //   return this.$message.warning('请至少选择一道相似题')
      // }
      this.addQuestions()
      this.centerDialogVisible = false
    },
    // 完成事件
    addItemBankEvent () {
      const self = this
      if (self.select.grade == null) {
        this.$message({ type: 'error', message: '年级不允许为空' })
        self.itemFocus(self.select.gradeId)
        return
      }
      if (self.select.subject == null) {
        this.$message({ type: 'error', message: '学科不允许为空' })
        self.itemFocus(self.select.subjectId)
        return
      }
      if (self.select.teachingAids == null) {
        this.$message({ type: 'error', message: '教辅不允许为空' })
        self.itemFocus(self.select.teachingAidsId)
        return
      }
      if (self.chaptValue && self.chaptValue.length <= 0) {
        this.$message({ type: 'error', message: '章节不允许为空' })
        return
      }
      if (self.select.classHourIds && self.select.classHourIds.length <= 0) {
        this.$message({ type: 'error', message: '课时不允许为空' })
        return
      }
      if (self.select.questionType == null) {
        this.$message({ type: 'error', message: '题型不允许为空' })
        self.itemFocus(self.select.questionTypeId)
        return
      }
      if (self.select.questionNumber == null || this.select.questionNumber === '') {
        this.$message({ type: 'error', message: '题号不允许为空' })
        self.itemFocus(self.select.questionNumberId)
        return
      }
      if (self.edit_area_content.tgContent == null || this.edit_area_content.tgContent === '') {
        this.$message({ type: 'error', message: '题干不允许为空' })
        self.itemFocus(self.edit_tags.tg)
        return
      }
      if (self.coordinateList.filter(item => {
        return item.length > 0
      }).length === 0) {
        return this.$message({ type: 'error', message: '坐标未选择' })
      }
      self.$nextTick(function () {
        self.commonsVariable.MathQueue()
      })
      chekRepeat({
        stem: this.edit_area_content.tgContent,
        subject: this.select.subject,
        grade: this.select.grade
      }).then(res => {
        if (res.code === 0) {
          this.checkQuestionList = res.data
          if (this.checkQuestionList.length > 0) {
            this.centerDialogVisible = true
          } else {
            this.addQuestions()
          }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).then(() => {
        self.$nextTick(function () {
          self.commonsVariable.MathQueue()
        })
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    addQuestions () {
      const self = this
      self.coordList = []
      self.pageCountList = []
      self.coordinateList.map((item, index) => {
        if (item.length > 0) {
          self.coordList.push(item)
          self.pageCountList.push(index)
        }
      })
      const classHourIds = self.select.classHourIds.slice(-1)[0]
      const data = {
        // 学科
        subject: self.select.subject,
        // 年级
        grade: self.select.grade,
        // 题型
        questionType: self.select.questionType,
        // 难易程度
        degreeOfDifficulty: 'SIMPLE',
        // 题目来源
        titleSource: 'TEACHING_MATERIAL',
        // 教材ID
        teachingMaterialId: self.select.teachingAids,
        // 章节id
        chapterIds: [this.chaptNameIdList[0].id],
        // 章节名称
        chapterNames: [this.chaptNameIdList[0].name],
        // 课时
        classHourIds,
        // 页码
        pageNumbers: self.pageCountList,
        // 题号
        titleNumber: self.select.questionNumber,
        // 查重id
        bankId: self.checked,
        propertyId: self.propertyId,
        // 坐标(数组形式)
        coordinates: (() => {
          const coordinates = []
          let index = 0
          self.coordList.forEach(p1 => {
            coordinates[index] = []
            p1.forEach(poss => {
              coordinates[index].push(self.handlerPosition(poss))
            })
            index++
          })
          return coordinates
        })(),
        // 题干(HTML类型)
        stem: self.edit_area_content.tgContent,
        // 答案(HTML类型)
        answer: self.edit_area_content.daContent,
        // 解析(HTML类型)
        analysis: self.edit_area_content.jxContent
      }
      self.addItemBankLoading = true
      addQuestionBank(data).then(response => {
        self.addItemBankLoading = false
        if (response.code === SETTING.NUMBER.ZERO) {
          self.clearData()
          self.checked = ''
          self.bankId = ''
          self.propertyId = ''
          // self.pageNumberList = []
          this.$message({ type: 'success', message: '录题成功' })
        } else {
          this.$message({ type: 'error', message: '录题失败' + response.msg })
        }
      }).catch(error => {
        self.addItemBankLoading = false
        console.error(error)
        this.$message({ type: 'error', message: '录题失败' })
      })
    },
    // 清空数据
    clearData () {
      if (this.coordinateList.length > 0) {
        this.dbObjectList.forEach(item => {
          item.clearCanvasV2()
        })
        this.coordinateList = []
        this.coordinateShowText = null
        this.alreadySelectCoordinateCount = SETTING.NUMBER.ZERO
      }
      this.edit_area_content.tgContent = null
      this.edit_area_content.daContent = null
      this.edit_area_content.jxContent = null
      this.select.questionNumber = null
      this.select.questionType = null
    },
    // 操作区点击切换事件
    handleClick (tab, event) {
      this.pageNumber = JSON.parse(JSON.stringify(tab.name)).substring(1, tab.name.length)
    },
    // 添加页数事件
    add () {
      this.pageNumber++
    },
    // 减少页数事件
    subtract () {
      if (this.pageNumber <= 1) {
        this.pageNumber = 1
      } else {
        this.pageNumber--
      }
    },
    // 确认页数事件
    pageActionOk () {
      this.showSelectPicture = false
      // 校验是否已经添加了此页数
      if (this.pageNumberList.find((n) => n === this.pageNumber)) {
        this.$message({ type: 'warning', message: '页数已经添加,不允许重复添加' })
        return
      }
      if (this.pageNumberList.length >= this.settings.maxHandlerAreaTabCount) {
        this.$message({ type: 'warning', message: '已经到了最大操作区域个数限制' })
        return
      }
      this.pageNumberList.push(this.pageNumber)
      this.handlerAreaActiveName = 'n' + this.pageNumber
      // 图片上传触发
      // document.getElementById('handler-area-image-upload').getElementsByTagName('input')[SETTING.NUMBER.ZERO].click()
      this.coordinateList[this.pageNumber] = []
    },
    // 编辑器之分数事件
    editorFraction () {
      // 清除数据
      this.fraction.molecule = null
      this.fraction.denominator = null
      // 弹出输入分数的框
      this.fractionDialogVisible = true
    },
    // 分数确认操作
    fractionActionOk () {
      if (this.fraction.molecule == null || this.fraction.denominator.length == null) {
        this.$message({ type: 'warning', message: '请输入分子/分母' })
        return
      }
      let str = STR_FRACTION
      str = str.replace(YANJU_CHARS.NUMERATOR, this.fraction.molecule)
      str = str.replace(YANJU_CHARS.DENOMINATOR, this.fraction.denominator)
      this.contentHandler(str)
      this.fractionDialogVisible = false
      this.itemFocus(this.editAreaActiveName)
    },
    // 编辑器之图片事件
    editorImage () {
      // 图片上传触发
      document.getElementById('input-area-image-upload').getElementsByTagName('input')[SETTING.NUMBER.ZERO].click()
      this.itemFocus(this.editAreaActiveName)
    },
    // 添加空格
    editAddSpace () {
      this.contentHandler('\\(\\ \\)')
    },
    // 键盘添加空格
    editKeyupSpace (e) {
      e = e || window.event
      const ctrlKey = e.ctrlKey
      const keyCode = e.keyCode || e.which || e.charCode
      if (ctrlKey && keyCode === 32) {
        this.contentHandler('\\(\\ \\)')
        return false
      }
    },
    // 添加除号
    editAddDivide () {
      this.contentHandler('\\(\\div\\)')
    },
    // 换行事件
    editorNewLine () {
      this.contentHandler('<br />')
    },
    // 获取到选中的文本
    getSelectedText () {
      // 先获取到选中文本
      let text = ''
      let obj = null
      switch (this.editAreaActiveName) {
        case TG_NAME:
          obj = document.getElementById(TG_NAME)
          break
        case DA_NAME:
          obj = document.getElementById(DA_NAME)
          break
        case JX_NAME:
          obj = document.getElementById(JX_NAME)
          break
      }

      if (document.selection) {
        text = document.selection.createRange().text.toString()
      } else if (obj.selectionStart) {
        text = obj.value.substring(obj.selectionStart, obj.selectionEnd)
      } else {
        text = window.getSelection().toString()
      }
      if (text.trim().length === 0) {
        return null
      }
      return text
    },
    // 文字下划线
    textUnderline () {
      const text = this.getSelectedText()
      if (text) {
        // 加上样式
        const hl = '<span style="text-decoration: underline">' + text + '</span>'
        this.contentHandler(hl)
      }
    },
    // 波浪线
    underWavy () {
      const text = this.getSelectedText()
      if (text) {
        // 加上样式
        const hl = '<span style="text-decoration: underline wavy black">' + text + '</span>'
        this.contentHandler(hl)
      }
    },
    // 着重号
    editorKeySymbol () {
      const text = this.getSelectedText()
      if (!text) {
        return
      }
      const style = definedKeySymbolStyle()
      // 校验是否已经添加过style
      const content = this.edit_area_content[this.editAreaActiveName + 'Content']
      if (content.indexOf('.' + KEY_SYMBOL_CLASS_NAME) === -1) {
        this.contentHandler(style)
      }
      let hl = ''
      for (let i = 0; i < text.length; i++) {
        hl += '<span class="' + KEY_SYMBOL_CLASS_NAME + '">' + text[i] + '</span>'
      }
      this.contentHandler(hl)
    },
    // 编辑器之下划线事件
    editorUnderline () {
      this.contentHandler(STR_UNDERLINE)
    },
    // 大于等于号
    editAddGreater () {
      this.contentHandler('\\(\\geq\\)')
    },
    // 小于等于号
    editAddLess () {
      this.contentHandler('\\(\\leq\\)')
    },
    // and
    editAddAnd () {
      this.contentHandler('\\(\\&\\)')
    },
    // times
    editTimes () {
      this.contentHandler('\\(\\times\\)')
    },
    // 处理编辑区内容插入
    contentHandler (str) {
      const textareaObject = document.getElementById(this.editAreaActiveName)
      insertText(textareaObject, str)
      const newVal = textareaObject.value || null
      this.itemFocus(this.editAreaActiveName)
      textareaObject.value = newVal
      switch (this.editAreaActiveName) {
        case this.edit_tags.jx:
          this.edit_area_content.jxContent = newVal
          break
        case this.edit_tags.da:
          this.edit_area_content.daContent = newVal
          break
        case this.edit_tags.tg:
          this.edit_area_content.tgContent = newVal
          break
      }
    },
    // 题库录入区上传前校验
    beforeInputImageAvatarUpload (file) {
      if (this.checkUploadImage(file)) {
        // 图片上传操作
      }
      // 不使用upload自带的上传方式，而是使用axios，所以阻止upload自带的上传
      return false
    },
    // 题库录入之图片上传成功事件
    inputImageChangeEvent (file, fileList) {
      const self = this
      self.showLoading('图片上传中...')
      // oss上传图片
      const fs = file.raw
      const newFileName = this.$common.getMyd() + '/' + this.$common.getUuid() + '.' + this.$common.getImageSuffix4ImageType(fs.type)
      self.$oss.put(newFileName, fs).then(function (r1) {
        self.closeLoading()
        // 上传成功
        self.$message({ type: 'success', message: '图片上传成功' })
        // 写入文本中
        const imageUrl = SETTING.URL.IMAGE_URL + '/' + newFileName
        const str = '<img src=\'' + imageUrl + '\' alt=\'题库\'/>'
        self.contentHandler(str)
      }).catch(err => {
        self.closeLoading()
        console.error(err)
        // 上传失败
        self.$message({ type: 'error', message: '图片上传失败' })
      })
    },
    // 操作区上传前校验
    beforeAreaImageAvatarUpload (file) {
      if (this.checkUploadImage(file)) {
        this.imagePreview(file)
      }
      // 不使用upload自带的上传方式，而是使用axios，所以阻止upload自带的上传
      return false
    },
    // 图片上传前校验图片
    checkUploadImage (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isOutMaxImageSize = file.size / this.settings.maxImageSize < SETTING.NUMBER.ONE
      if (!isJPG && !isPNG) {
        this.$message({ type: 'error', message: '上传头像图片只能是 JPG 或 PNG 格式!' })
        this.pageNumberList.pop()
        return false
      } else if (!isOutMaxImageSize) {
        this.$message({ type: 'error', message: '上传头像图片大小不能超过' + (this.settings.maxImageSize / SETTING.NUMBER.N1024 / SETTING.NUMBER.N1024) + 'MB!' })
        this.pageNumberList.pop()
        return false
      } else {
        return true
      }
    },
    // 显示loading
    showLoading (msg) {
      console.log('Loading')
    },
    // 关闭loading
    closeLoading () {
      if (this.loadingObject != null) {
        this.loadingObject.close()
      }
    },
    // 处理坐标数据
    handlerPosition (v) {
      const xMin = +v.data[SETTING.NUMBER.ZERO].x.toFixed(SETTING.NUMBER.TWO)
      const yMin = +v.data[SETTING.NUMBER.ZERO].y.toFixed(SETTING.NUMBER.TWO)
      const xMax = +v.data[SETTING.NUMBER.TWO].x.toFixed(SETTING.NUMBER.TWO)
      const yMax = +v.data[SETTING.NUMBER.TWO].y.toFixed(SETTING.NUMBER.TWO)
      return {
        x_min: xMin,
        y_min: yMin,
        x_max: xMax,
        y_max: yMax
      }
    },
    // 图片显示
    imagePreview: function (file) {
      const self = this
      // 上传至OSS
      const newFileName = 'teaching-material/' + self.select.teachingAids + '/' + self.pageNumber + '.' + self.$common.getImageSuffix4ImageType(file.type)
      self.$oss.put(newFileName, file).then(function (r1) {
        self.closeLoading()
        // 上传成功
        self.$message({ type: 'success', message: '图片上传成功' })
        // 上传至DB
        imageUpload({
          questionImage: SETTING.URL.IMAGE_URL + '/' + newFileName,
          page: self.pageNumber,
          teachingMaterialId: self.select.teachingAids
        }).then(response => {
          if (response.code !== SETTING.NUMBER.ZERO) {
            self.$message({ type: 'error', message: response.msg })
          }
        })
        // 定义一个文件阅读器
        const reader = new FileReader()
        // 文件装载后将其显示在图片预览里
        reader.onload = function (e) {
          const displayAreaObject = document.getElementById('display_area_' + self.pageNumber)
          const regionCanvasObject = document.getElementById('region_canvas_' + self.pageNumber)
          const imageCanvasObject = document.getElementById('image_canvas_' + self.pageNumber)
          const canvasLayerObject = document.getElementById('canvas_layer_' + self.pageNumber)

          function syncPosition () {
            self.alreadySelectCoordinateCount = SETTING.NUMBER.ZERO
            const data = self.dbObjectList[self.pageNumber].pick() || []
            if (data.length > SETTING.NUMBER.ZERO) {
              self.coordinateList[self.pageNumber] = data
            }
            // 输出坐标显示
            let coordinateText = ''
            self.coordinateList.forEach((list, page) => {
              coordinateText += '页数:' + page + '<br />'
              list.forEach((v, ix) => {
                self.alreadySelectCoordinateCount++
                const pos = self.handlerPosition(v)
                coordinateText += (ix + SETTING.NUMBER.ONE) + ': ' + JSON.stringify(pos) + '<br />'
              })
            })
            self.coordinateShowText = coordinateText
          }

          const dt = new DrawTool(displayAreaObject, regionCanvasObject, imageCanvasObject, canvasLayerObject, syncPosition)

          // 缓存到列表中
          self.dbObjectList[self.pageNumber] = dt
          dt.clearCanvas()
          dt.loadImage(this.result).then(() => {
            // 同步加载完毕后执行
            console.log('do nothing')
          }).catch(() => {
            self.$message({ type: 'error', message: '同步加载失败,请刷新页面重新操作' })
          })
        }
        reader.readAsDataURL(file)
      }).catch(err => {
        self.closeLoading()
        console.error(err)
        // 上传失败
        self.$message({ type: 'error', message: '图片上传失败' })
      })
    }
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  overflow: auto !important;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

#topic {
  box-sizing: border-box;
  padding: 10px 0 20px 10px;
  width: 100%;
  height: 100%;
  display: flex;
}

.homepage-left,
.homepage-middle,
.homepage-right {
  box-sizing: border-box;
  margin-right: 10px;
}

.homepage-left {
  width: 33%;
  height: 100%;
  border: 1px solid #CCF4E0;
  border-radius: 5px;
}

.homepage-middle {
  width: 33%;
  height: 100%;
  border-radius: 5px;
}

.homepage-right {
  width: 33%;
  height: 100%;
  display: inline;
  box-sizing: border-box;
}

.homepage-right-header {
  width: 100%;
  height: 30%;
  border: 1px solid #CCF4E0;
  margin-bottom: 10px;
}

.homepage-right-middle {
  width: 100%;
  height: 20%;
  border: 1px solid #CCF4E0;
  flex-grow: 1;
  margin-bottom: 10px;
}

.homepage-right-footer {
  width: 100%;
  height: 20%;
  border: 1px solid #CCF4E0;
}

.header {
  width: 100%;
  height: 50px;
  background: red;
}

.heads {
  width: 100%;
  height: 40px;
  background: #CCF4E0;
}

.heads p {
  line-height: 40px;
  margin-left: 15px;
  color: #00B87B;
  margin-right: 30px;
}

.headss {
  width: 100.2%;
  height: 40px;
  background: #CCF4E0;
}

.headss p {
  line-height: 40px;
  margin-left: 15px;
  color: #00B87B;
  margin-right: 30px;
}

.complete {
  width: 100%;
  color: white;
  background: #1EB180;
  margin-top: 4%;
}

.homepage-right-btn {
  padding: 10px;
}

.Operation {
  height: 75%;
  border: 0.5px solid #CCF4E0;
}

.homepage-middle-middle {
  margin: 10px auto;
  height: 90px;
  overflow: hidden;
  overflow-y: scroll;
}

.homepage-middle-btn {
  display: flex;
  justify-content: space-between;
}

.homepage-middle-btn1 {
  width: 40%;
  border: 1px solid #1EB180;
  color: #1EB180;
}

.homepage-middle-btn2 {
  width: 40%;
  background: #1EB180;
  color: white;
}

.el-tabs__item {
  color: #1EB180;
}

.el-tabs__item.is-active {
  color: #333333;
}

.el-tabs__active-bar {
  background: #1EB180;
}

.homepage-left-middle-btn {
  color: #1EB180;
  border: 1px solid #1EB180;
}

.el-textarea__inner {
  line-height: 1.5;
}

/* .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2){
  padding-left: 35px;
} */

.el-tabs__active-bar {
  background: #1EB180;
}

.el-tabs__nav-wrap::after {
  background: #CCF4E0;
}

.homepage-page {
  display: flex;
  margin-top: 40px;
  margin-left: 20%;
}

.homepage-page p {
  text-align: center;
  margin: 0 15px;
}

.nums {
  width: 60px;
  height: 30px;
  border: 0 solid white;
  border-bottom: 1px solid #CCC;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
}

.page_handle {
  color: #1EB180;
  font-size: 30px;
  margin-top: 7px;
  cursor: pointer;
}

#page-action-ok {
  width: 80%;
  background: #1EB180;
  border: 1px solid #1EB180;
  color: white;
}

.hide {
  display: none
}

.btn {
  cursor: pointer
}

.editor_textarea {
  margin: 12px auto;
  height: 100%;
  width: 100%;
}

.preview-area {
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
  height: 100%;
}

.preview-area img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.topic_flex {
  display: flex;
  flex-direction: column;
}

.topic_flex_item {
  flex: 1;
}

#topic .inputForm {
  margin-top: 10px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
}

#topic .inputForm .el-form-item {
  width: 50%;
}

#topic .inputForm .el-select,
#topic .inputForm .el-input {
  max-width: 200px;
}

.home-right-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check {
  width: 35%;
  color: white;
  background: #1EB180;
  margin-top: 4%;
}

.checkContent {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.checkTitle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #1EB180;
  font-weight: 600;
  background-color: #E8F7F2;
}

.checkTitle p {
  margin: 0 20px;
}

.checkLeft {
  width: 49%;
  border: 1px solid #1EB180;
  border-radius: 10px;
  overflow: hidden;
}

.checkRight {
  width: 49%;
  border: 1px solid #1EB180;
  border-radius: 10px;
  overflow: hidden;
}

.checkLeft .checkText {
  padding: 20px;
  height: 500px;
  /* overflow-y: scroll; */
  overflow: auto;
}

.checkRight .checkText {
  padding: 10px;
  background-color: #EEEEEE;
  height: 520px;
  /* overflow-y: scroll; */
  overflow: auto;
}

.checkLeft .checkText img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.checkRight .checkText img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.checkRight .checkText .context {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.headers {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.headers:nth-last-child(1) {
  margin-bottom: 0;
}

.similar {
  font-size: 14px;
  color: #1EB180;
  font-weight: 600;
  /* margin-left: 10px; */
  /* margin-bottom: 2px; */
}

.contents {
  color: #666;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1EB180;
  border-color: #1EB180;
}

.el-checkbox__inner {
  border-radius: 50%;
}

.el-radio__input.is-checked .el-radio__inner {
  background-color: #1EB180;
  border-color: #1EB180;
}

.materialBox {
  max-height: 500px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
}

.materialBox div {
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  background: #00B87B;
  color: #fff;
  cursor: pointer;
  margin: 0 2px 15px 0;
}

.el-button--primary {
  background-color: #00B87B;
  border: 1px solid #00B87B;
}

.el-button--primary:hover {
  background-color: #3bc094;
  border: 1px solid #00B87B;
}
.el-scrollbar__wrap{
  min-height: 50px;
}
</style>
